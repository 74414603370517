/* ==========================================================================
   Site wide styles
   ========================================================================== */

/*
   Selection
   ========================================================================== */

::-moz-selection {
	background-color: lighten($basecolor, 65%);
	color: $basecolor;
	text-shadow: none;
}
::selection {
	background-color: lighten($basecolor, 65%);
	color: $basecolor;
	text-shadow: none;
}

/*
   Global classes
   ========================================================================== */

/* Capitalize */
.all-caps {
	text-transform: uppercase;
}

/* Float left */
.pull-left {
	float: left;
}

/* Float right */
.pull-right {
	float: right;
}
.image-pull-right {
	float: right;
	margin-top: 0;
}

/* Clearfix */
.clearfix {
	*zoom: 1;
	&:before,
	&:after {
		display: table;
	    content: "";
	}
	&:after {
		clear: both;
	}
}

/* Remove bullets and indentation from list */
.unstyled-list {
	list-style: none;
	margin-left: 0;
	padding-left: 0;
	li {
		list-style-type: none;
	}
}

/* style tables */
table td,table th{padding:10px;border:1px solid #ddd}

/*
   Global transition
   ========================================================================== */

b, i, strong, em, blockquote, p, q, span, figure, img, h1, h2, header, input, a {
	@include transition(all .2s ease);
}
