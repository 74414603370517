/* ==========================================================================
   PRINT STYLES
   ========================================================================== */

/* 
   Hide the following elements on print
   ========================================================================== */

@media print {
  .top-navigation, 
  .related-articles,
  .google-ads {
    display: none;
  }
}
